import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo.js"
import GridIcons from "../components/content/gridicons"
import Helmet from "react-helmet"

import Subscribe from "../components/subscribe"
import { Menu, MenuCTA } from "../components/content/menu"

const SignUpPage = ({ siteTitle, data }) => (
  <StaticQuery
    query={graphql`
      query SignUpPageQuery {
        site {
          siteMetadata {
            title
            blogUrl
            appUrl
            getStartedUrl
            twitterUrl
            linkedInUrl
            feedbackUrl
            description
            homeDescription
            homeDescription2
            homeDescription3
            footerDescription
          }
        }
      }
    `}
    render={data => (
      <>
        {/* // Inside your component */}
        <Helmet
          bodyAttributes={{
            class: "home",
          }}
        />{" "}
        <Helmet>
          {/* <!-- IE10 viewport hack for Surface/desktop Windows 8 bug --> */}
          <link
            href="/theme/css/ie10-viewport-bug-workaround.css"
            rel="stylesheet"
          />

          {/* <!-- Custom styles for this template --> */}
          {/* <link href="/theme/css/cover.css" rel="stylesheet"/> */}
        </Helmet>
        <Layout>
          <SEO title={data.site.siteMetadata.description} />
          <div
            className="jumbotron page-header pt-6 pb-6 mb-6 mt-24"
            style={{ backgroundColor: "transparent" }}
          >
            <div className="container">
              <h1 className="display-32"> </h1>
              <h2 className="display-32"> </h2>
              <p> </p>{" "}
              {/* <p>
                <Menu />{" "}
              </p> */}
            </div>
          </div>

          <div className="site-wrapper">
            <div className="site-wrapper-inner">
              <div className="cover-container">
                {/* 
    <div className="masthead clearfix">
      <div className="inner">
        <h3 className="masthead-brand">Cover</h3>
        <nav>
          <ul className="nav masthead-nav">
            <li className="active"><a href="#">Home</a></li>
            <li><a href="#">Features</a></li>
            <li><a href="#">Contact</a></li>
          </ul>
        </nav>
      </div>
    </div> */}

                <div className="inner cover text-center">
                  {/* <div className="row"> */}
                  {/* <div className="col-md-12 col-md-offset-1b col-sm-12 text-center"> */}
                  <h1 className="cover-heading">Sign Up for Mailing List</h1>
                  <br />
                  {/* <p>
                    
                  </p> */}
                  {/* </div> */}
                  {/* </div> */}
                  {/* <h1 className="cover-heading">Cover your page.</h1> */}
                  <p />{" "}
                  <p>
                    <br />
                    <span className="text-muted">
                      Request to add to mailing list
                    </span>

                    <br />
                  </p>
                  <p>
                    {/* <p className="lead"> */}
                    {/* <a href="/" className="btn btn-lg btn-default">
                      Learn more
                    </a> */}
                    <Subscribe />
                    {/* </p>   */}
                    <br />
                  </p>
                </div>
              </div>
              <div>
                <div className="page-header2 text-center">
                  <div className="cover-container2">
                    {/* <hr/><br/> */}
                    {/* <h2>Simplifying data privacy trust &amp; transparency.</h2> */}
                    {/* <p className="lead text-primary pb-2 pt-2">
        Free data protection intelligence on apps, sites &amp; providers for people with premium benefits for businesses.
      </p>  */}

                    {/* <div className="col-xs-auto">
                    <img alt={process.env.REACT_APP_NAME}  src="/images/b2bscreenshot1.png" className="center-block  img-responsive mx-auto" />
                    
                    </div>      */}
                    {/*
                    <div className="container mr-auto ml-auto text-center ">
      <img alt={process.env.REACT_APP_NAME}    src="/images/b2bscreenshot1.png" className="pt-4 center-block img-responsive border border-light" />
    </div> */}
                  </div>
                </div>
                {/* <div className="container mr-auto ml-auto text-center ">

<hr/>           <br/>
      <img alt={process.env.REACT_APP_NAME}  width="90%" src="/images/b2bscreenshot1.png" className="center-block img-responsive border border-light" />
    </div>  */}
                <div className="cover-container">
                  <div className="mastfoot  text-center">
                    <div className="inner">
                      <div className={"copyright"}>
                        <p>
                          <small>
                            Copyright {new Date().getFullYear()}.{" "}
                            <a
                              href={'/'}
                              title={data.site.siteMetadata.title}
                            >
                              {data.site.siteMetadata.title}
                            </a>
                            .{/* All rights reserved.   */}{" "}
                            <Link to="/privacy" title={"Privacy Policy"}>
                              Privacy Policy
                            </Link>
                          </small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </>
    )}
  />
)

SignUpPage.propTypes = {
  siteTitle: PropTypes.string,
}

SignUpPage.defaultProps = {
  siteTitle: ``,
}

export default SignUpPage
